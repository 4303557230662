.NavC{
    position: sticky;
    top: 0;
    display: none;
    z-index: 2000000;
    position: relative;
}

.navc{
    height: fit-content;
    overflow-y: auto;
}

.top{
    background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 23;
    position: relative;
    padding-inline: .7rem .5rem;
    font-weight: bold;
    font-size: 20px;
    color: orangered;
}


.others{
    padding-inline: 1rem;
    transition: 300ms all;
    z-index: 2;
    position: fixed;
    width: 100%;
    top: -350px;
    background: black;
    padding-inline: 1rem;
    height: fit-content;
    padding-block: 1rem;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
} 
.othersslide{
    background: black;
    padding-inline: 1rem;
    height: fit-content;
    padding-block: 1rem;
    position: fixed;
    transition: 300ms all;
    width: 100%;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50px;
} 

.others ul{
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    list-style: none;
}

.others ul li{
    text-decoration: none;
    text-align: center;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}

.others ul a{
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
}

















@media (max-width: 1000px) {
    .NavC{
        position: sticky;
        top: 0;
        display: block;
    }
}