.invest{
    background: black;
    margin-top: -5px;
}

.cards{
    display: grid;
    padding: 1rem;
    padding-top: 0;
    grid-template-columns: max-content max-content max-content max-content max-content;
    justify-content: center;
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: 1rem;
}

.card{
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    border-radius: 2px;
    gap: 1rem;
    border: 4px solid #656565;
    padding: 1rem;
    text-transform: uppercase;
    transform: scale(.95);
    background: #656565;
    justify-content: space-between;
    color: white;
    transition: 500ms;
    cursor: pointer;
}

.card:hover{
    border: 4px solid white;
    transform: scale(1);
    background: linear-gradient(orangered 30%,  orange);
}

.content{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-block: .5rem;
    font-size: 13px;
}
#content{
    display: flex;
    gap: .4rem;
}

.plan{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
}

.percentage{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
}

.period{
    font-weight: 600;
    font-size: 13px;
}

.card>:last-child{
    width: 100%;
    padding: .8rem 1rem;
    background: white;
    font-weight: bold;
    font-size: 13px;
    display: grid;
    border-radius: 2px;
    place-items: center;
    color: black;
    text-align: center;
} 
@media (max-width: 1300px) {
    .cards{
        display: grid;
        padding: 1rem;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        justify-content: start;
        grid-auto-rows: auto;
        width: 100%;
        overflow-x: auto;
        scroll-padding-inline: 1rem;
        gap: 1rem;
    }
 
}
@media (max-width: 900px) {
    .cards{
        display: grid;
        padding: 1rem;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        justify-content: start;
        grid-auto-rows: auto;
        width: 100%;
        overflow-x: auto;
        gap: 1rem;
    }
 
}