.about{
    height: fit-content;
    background: green;
    margin-top: -1px;
}

.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: black;
    padding-block: 2rem;
    gap: 2rem;

}
.leftt{
    height: 100%;
    width: 100%;
    background: url('./images/images/pexels-alesia-kozik-6771900.jpg') no-repeat center/contain;
    background-size: auto;
    min-height: 200px;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.2rem;
    color: white;
}

.right>:nth-child(1){
    font-weight: bold;
    font-size: 18px;

}
.right>:nth-child(2){
    font-size: 15px;
    line-height: 1.3rem;

}

.right>:nth-child(3){
    display: flex;
    gap: 1rem;
    font-size: 15px;
}


.right>:nth-child(4){
    font-size: 15px;
    line-height: 1.3rem;
}

.right>:nth-child(3)>:nth-child(1){
    color: orangered;
    font-weight: bold;
    border-right: 2px solid white;
    cursor: pointer;
    padding-right: 1rem;
}

.right>:nth-child(3)>:nth-child(2){
    border-right: 2px solid white;
    cursor: pointer;
    padding-right: 1rem;
}


.right>:nth-child(3)>:nth-child(3){
    cursor: pointer;
}

.right>:last-child{
    cursor: pointer;
    width: fit-content;
    padding: .75rem 2rem;
    margin-top: .5rem;
    background-color: orangered;
    font-weight: bold;
    font-size: 14px;
    transform: scale(1);
    color: white;

}
.right>:last-child:hover{

    transform: scale(1.05);
    transition: all 300ms;

}


@media (max-width: 700px) {
    
    
.container{
    display: grid;
    grid-template-columns: 1fr;
    background: black;
    padding-block: 2rem;
    padding-inline: 1rem;
}
    

.leftt{
    background: url('./images/images/pexels-alesia-kozik-6771900.jpg') no-repeat center/contain;
    background-size: auto;
    background-clip: border-box;
    order: 3;
    min-height: 200px;
}

.right{
    order: 2;
}
}