.slideshow{
    height: 70vh;
    background: lightgray;
}
 

@media (max-width: 1000px) {
    .slideshow{
        height: 50vh;
        background: lightgray;
    }
     
}