.started{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    text-align: center;
    background: url('./images/images/pexels-pnw-production-8251001.jpg') no-repeat  center/cover ;
    padding-block: 3.5rem;
}

.started>:nth-child(1){
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: white;
}
.started>:nth-child(2){
    font-size: 15px;
    color: whitesmoke;
}
.started>:nth-child(3){
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background: orangered;
    cursor: pointer;
    padding: .8rem 2rem;
    border-radius: 3px;
    transition: 500ms all;
}
.started>:nth-child(3):hover{
    background: white;
    color: red;
}

@media (max-width: 750px) {
    
.started>:nth-child(3){
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background: orangered;
    cursor: pointer;
    padding: .8rem 1.2rem;
    border-radius: 3px;
    transition: 500ms all;
}
    
}