.statistics{
    background: grey;
}


.statsrectangle{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    background: grey;
    padding-bottom: 3rem;
    justify-content: space-between;
    margin-top:-1px;
 }
 .stats{
    display: grid;
    gap: 1rem;
    justify-content: space-evenly;
    color: white;
    grid-template-columns: repeat(3, 1fr);
    text-align:center;
 }

 
 .borderright{
    border-right: 3px solid white;
}

.stats div{
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

 .stats div>:nth-child(1){
    font-size: 27px;
    font-weight: 700;
 }
 .stats div>:nth-child(2){
    font-size: 14px;
 }


 .statsbtn{
    display: flex;
    justify-content: center;
    font-weight: bold;
    gap: 1.5rem;
    font-size: 14px;
 }
 .statsbtn>:nth-child(1){
    background: black;
    color: orangered;
    padding: .5rem 1.5rem;
    border:2px solid orangered;
    border-radius: 3px;
    cursor: pointer;
}

.statsbtn>:nth-child(2){
    align-self: center;
    font-size: 15px;
    color: white;
}


.statsbtn>:nth-child(3){

    border-radius: 3px;
    padding: .5rem 1rem;
    cursor: pointer;
    background: orangered;
   color: white;
   border:2px solid orangered;

}

@media (max-width: 750px) {
    
 .stats{
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    justify-content: space-evenly;
    color: white;
    text-align:center;
 } 

.borderright{
    border-right: none;
}

 .stats div>:nth-child(1){
    font-size: 25px;
    font-weight: 700;
 }
 .stats div>:nth-child(2){
    font-size: 13px;
 }


 .statsbtn>:nth-child(1){
    padding: .5rem 1rem;
}
 


.statsbtn>:nth-child(3){

    padding: .5rem;

}

}

@media (max-width: 350px) {
    
 .stats{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    justify-content: space-evenly;
    color: white;
    text-align:center;
 }
 .stats div>:nth-child(1){
    font-size: 23px;
    font-weight: 700;
 }
 .stats div>:nth-child(2){
    font-size: 13px;
 }


 .statsbtn>:nth-child(1){
    padding: .5rem .5rem;
    font-size: 12px;
}
 


.statsbtn>:nth-child(3){

    font-size: 12px;
    padding: .3rem;

}

}