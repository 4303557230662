.bitcoin{
    background: black;
    height: 100px;
    position: relative;
}

.bitcoinboxes{
    display: flex;
    gap: 1rem;
    justify-content: space-evenly;
    color: white;
    padding: 2rem 1rem;
    background: rgb(56, 56, 56);
    width: 95%;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
}

.bitcoinbox{
    display: flex;
    gap: 1rem;
    cursor: pointer;
}


.bitcoinbox__img>*{
    stroke: orangered;
}

.bitcoinbox__content{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.bitcoinbox__content>:nth-child(1){
    font-weight: 700;
    font-size: 15px;
}
.bitcoinbox__content>:nth-child(2){
    font-weight: 500;
    font-size: 14px;
}

@media (max-width: 700px) {
    .bitcoin{
        display: none;
    }
    
}