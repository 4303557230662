.NavB{
    position: sticky;
    top: 0;
    z-index: 155555;
}

.bigscreenNav{
    position: relative;
}

 .links{
    height: 50px;
    background-color: black;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 5;
 }

 .links ul{
    display: flex;
    gap: 3rem;
    list-style: none;
    justify-content: center;
    align-items: center;
 }

 .links ul>:last-child>*{
    height: 22px;
    width: 22px;
    cursor: pointer;
 }

 .links a{
    text-decoration: none;
    font-size: 13px;
    color: white;
    transition: 1s;
}

 .links a:hover{
    color: orangered;
    transition: 1s;
}

.searchinput{
    width: 100%;
    height: 48px;
    background-color: rgba(117, 117, 117, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    transition: 400ms;
    z-index: 1;
    padding-inline: 2rem;
}

.slidesearch{
    width: 100%;
    height: 48px;
    background-color: rgba(117, 117, 117, 0.8);
    position: absolute;
    top: 48px;
    transition: 400ms;
    left: 0;
    padding-inline: 2rem;
}

.searchinput input{
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: transparent;
}

.cancel{
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
}

::placeholder{
    color: white;
}

.cancel>*{
    height: 25px;
    width: 25px;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .NavB{
        position: sticky;
        display: none;
        top: 0;
    }
}