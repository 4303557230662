.experts{
    margin-top: -1px;

}

.cubes{
    display: grid;
    justify-content: space-evenly;
    align-content: center;
    padding-inline: 5rem;
    padding-block: 1rem 3rem;
    background: black;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.cube{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    gap: .3rem;
    align-items: center;
}

.cube>:nth-child(2){
    font-weight: bold;
    font-size: 14px;
}

.cube>:nth-child(3){
    font-size: 13px;
}

.circle{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    margin-bottom: .5rem;
    background: rgb(124, 123, 123);
    border: 4px solid orangered;
}

@media (max-width: 850px) {
    .cubes{
        display: grid;
        justify-content: space-evenly;
        align-content: center;
        padding-inline: 5rem;
        padding-block: 1rem 3rem;
        gap: 2rem;
        background: black;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 550px) {
    .cubes{
        display: grid;
        justify-content: space-evenly;
        align-content: center;
        padding-inline: 5rem;
        padding-block: 1rem 3rem;
        gap: 2rem;
        background: black;
        grid-template-columns: 1fr;
    }
}