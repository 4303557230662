.contract{
    margin-top: -5px;
    background:black;

}

.contractCards{
    display: grid;
    padding: 1rem;
    padding-top: 0;
    grid-template-columns: max-content max-content max-content;
    justify-content: space-evenly;
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: 1rem;
    padding-bottom: 5rem;
    gap: 3rem;
}

.contractCard{
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    border-radius: 2px;
    gap: 1rem;
    border: 4px solid #656565;
    padding: 1rem;
    text-transform: uppercase;
    transform: scale(.95);
    background: #656565;
    justify-content: space-between;
    color: white;
    transition: 500ms;
    cursor: pointer;
}

.contractCard:hover{
    border: 4px solid white;
    background: linear-gradient(orangered 30%,  orange);
    transform: scale(1);
}

.contractContent{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-block: .5rem;
    font-size: 13px;
}
#contractContent{
    display: flex;
    gap: .4rem;
}

.contractPlan{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
}

.contractPercentage{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 25px;
}

.contractPeriod{
    font-weight: 600;
    font-size: 13px;
}

.contractCard>:last-child{
    width: 100%;
    padding: .8rem 1rem;
    background: white;
    font-weight: bold;
    font-size: 13px;
    display: grid;
    border-radius: 2px;
    place-items: center;
    color: black;
    text-align: center;
} 
@media (max-width: 1000px) {
    .contractCards{
        display: grid;
        padding: 1rem;
        grid-auto-flow: row;
        grid-template-columns: max-content max-content;
        grid-template-rows: auto 1fr;
        align-content: center;
        gap: 1rem;
    }

    .contractCards>:nth-child(3){
        justify-self: center;
        grid-column: 1/3;
        grid-row: 1/2;
    }
}
@media (max-width: 700px) {
    .contractCards{
        display: grid;
        padding: 1rem;
        grid-auto-flow: row;
        grid-template-columns: 280px;
        grid-template-rows: auto auto auto;
        align-content: center;
        gap: 1rem;
    }
     .contractCards>:nth-child(3){
        justify-self: stretch;
        grid-column: 1/2;
        grid-row: 3/4;
    }
    
}




