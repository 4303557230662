.worldcontainer{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    background: rgb(44, 44, 44);
    gap: 1rem;
    align-content: space-between;
    padding-block: 2rem 0;
}
.worldleft{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

#worldleft {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    padding-inline: 3rem;
}
#worldleft >:nth-child(1) > *{
    stroke: orangered;
}
#worldleft>:nth-child(2){
    color: white;
    font-size: 15px;
    font-weight: bold;
}
#worldleft>:nth-child(3){
    color: white;
    font-size: 14px;
}
#worldleft>:last-child{
    min-height: 75px;
}

.worldright{
    background: url('./images/images/pexels-fauxels-3184418.jpg') no-repeat center/cover;
    min-height: 200px;
    width: 100%;
}

@media (max-width: 900px) {
    .worldcontainer{
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
}

.worldleft{
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: .5rem;
}

#worldleft {
    display: flex;
    flex-direction: column;
    gap: .25rem;
    align-items: center;
    padding-inline: .3rem;
    justify-content: center;
}

#worldleft>:last-child{
    min-height: 75px;
}
}