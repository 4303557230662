.nava{
    height: 100px;
    background-color: rgb(131, 131, 131);
    display: grid;
    padding: 1rem;
    grid-template-columns: .7fr 2.5fr 1fr;
}

.logo{
    display: grid;
    place-items: center;
    font-size: 20px;
    color: orangered;
}

.data{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
#data{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .25rem;
}
#data>:nth-child(1){
    font-weight: 700;
    color: white;
    font-size: 15px;
}
#data>:nth-child(2){
    font-weight: 500;
    color: whitesmoke;
    text-transform: capitalize;
    font-size: 12px;
}

.btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.btns>div{
    display: flex;
    gap: .5rem;
    color: white;
    font-size: 12px;
    cursor: pointer;
    align-items: center;
    border-radius: 3px;
}

.loginbtn{
    background: black;
    border: 3px solid orangered;
    padding: .25rem 1.5rem;
}

.loginbtn-img>*{
    width: 20px;
    height: 20px;
}

.signupbtn{
    background: orangered;
    padding: .2rem 1.2rem;
    border: 3px solid orangered;
}
 


.signupbtn-img>*{
    width: 21px;
    height: 21px;
}

@media (max-width: 1000px) {
    .NavA{
        display: none;
    }
}