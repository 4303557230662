.headera{
    
    display: flex;
    flex-direction: column;
    background: rgb(0, 0, 0);
    padding: 3rem;
    gap: .5rem;
}
.headera>:nth-child(1){
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
}

.headera>:nth-child(2){
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 1rem;
    font-size: 13px;
    text-transform: uppercase;
    color: whitesmoke;
}



.line{
    width: 20px;
    height: 2px;
    background: orangered;
}

.headerb{
    font-size: 20px;
    color: white;
    padding-block: 3rem 2rem;
    text-align: center;
    background: rgb(0, 0, 0);
    font-weight: 600;
    text-transform: uppercase;
}


.headerc{
    
    display: flex;
    flex-direction: column;
    background: grey;
    padding-block: 3rem 2rem;
    gap: .5rem;
}
.headerc>:nth-child(1){
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    color: white;
    text-transform: uppercase;
}

.headerc>:nth-child(2){
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 1rem;
    font-size: 13px;
    text-transform: uppercase;
    color: whitesmoke;
}