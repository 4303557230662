*{
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  border: none;
  outline: none;
}
 
body{
  margin: auto;
  background: lightgray;
}

.orangeheader{
  color: orangered;
}